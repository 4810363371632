<template>
	<div>
		<TableList :form_data_seo.sync="form_data_seo" :columns="columns" :get_table_list="this.get_table_list"
			:slot_table_list="['operation','status','use_status','goods_logo','goods_put','is_manual','visit_status']"
			ref="list" :is_created_get_list="false" :submit_preprocessing="submit_preprocessing">
			<template slot="left_btn">
				<a-button class="float_left" v-auth="'change'" @click="add()" type="primary">添加套餐</a-button>
			</template>
			<template slot="operation" slot-scope="data">
				<a v-auth="'change'" @click="get_Binding_Info(data.record)">编辑</a>
				<a-divider v-auth="'change'" type="vertical" />
				<a-popconfirm title="您确定要删除吗?" v-auth="'delete'"
					@confirm="delBing(data.record)"><a>删除</a></a-popconfirm>
			</template>
		</TableList>
		<EditPop width='60%' ref='EditPop' :title="edit_type?'编辑套餐':'添加套餐'" @handleSubmit="add_submit"
			:form_data="form_data" :visible.sync="add_visible">
			<template slot='goods'>
				<div>
					<a-button style='margin-bottom:10px' @click="add_material()" type="primary">选择品项</a-button>
					<a-table :dataSource="material_list" :columns="columns_goods_list">
						<template slot="goods_num"  slot-scope="text, record, index">
							<a-input
							    type="number"
							    name="num"
							    v-model:value="record.goods_num"
							/>
						</template>
						<template slot="goods_price"  slot-scope="text, record, index">
							<a-input
							    type="number"
							    name="num"
							    v-model:value="record.goods_price"
							/>
						</template>
					</a-table>
				</div>
			</template>
		</EditPop>
		<SelectMaterial
		    @submit="select_material_submit"
		    :visible.sync="material_visible"
		></SelectMaterial>
	</div>
	
</template>

<script>
	// @ is an alias to /src
	import EditPop from "@/components/EditPop";
	import SelectMaterial from "@/components/SelectMaterial";
	import Form from "@/components/Form";
	import TableList from "@/components/TableList";

	import {
		freezerList,
		changeCompete,
		freezerLocus,
		changeFreezer,
		scrapBinding,
		bindingLost,
		bindingTransfer,
		changeStatus,
		delGoodsBinding,
		getBrandSelect,
		getBindingInfo,
		saveBindingInfo,
		exportBindingTemplate,
		getFreezerModel
	} from "@/api/goods";

	import {
		message
	} from 'ant-design-vue'
	let columns_goods_list = [{
			title: "品项",
			dataIndex: "goods_title",
		},
		{
			title: "数量",
			dataIndex: "goods_num",
			scopedSlots: {
				customRender: "goods_num",
			},

		},
		{
			title: "价格",
			dataIndex: "goods_price",
			scopedSlots: {
				customRender: "goods_price"
			},
		}
	]
	let columns = [{
			title: "ID",
			dataIndex: "goods_title",

		},
		{
			title: "套餐名称",
			dataIndex: "goods_model",
		},
		{
			title: "品项",
			dataIndex: "goods_code",
		},
		{
			title: "数量",
			dataIndex: "make_time",

		},
		{
			title: "价格",
			dataIndex: "customer_name",
		},
		{
			title: "状态",
			dataIndex: "customer_name2",
		},
		{
			title: "有效期",
			dataIndex: "customer_name3",
		},

		{
			title: "所属等级",
			dataIndex: "customer_name4",
		},
		{
			title: "操作",
			scopedSlots: {
				customRender: "operation"
			},
			fixed: 'right'
		},
	];

	let status_list = [{
			key: 1,
			value: "已绑定"
		},
		{
			key: 2,
			value: "预绑定"
		},
		{
			key: 0,
			value: "未绑定"
		},
		{
			key: -1,
			value: "报废"
		},
		{
			key: -2,
			value: "遗失"
		}
	];

	export default {
		name: "Index",
		components: {
			EditPop,SelectMaterial,
			Form,
			TableList,
		},
		data() {
			return {
				material_visible:false,
				columns_goods_list,
				material_list: [],
				nameplate_image: [],
				goods_code: '',
				goods_code_num: '',
				code_num: '',
				VisibleUpload: false,
				add_select_customer: {},
				add_pid_visible: false,
				editing_freezer: {}, // 编辑时的冰柜
				select_customer: {}, //转绑时选择的客户信息
				pid_visible: false, // 用户选择弹窗是否显示
				scrapReason: '', // 报废原因
				bindTransferReason: '', // 转绑原因
				edit_type: 1,
				get_table_list: freezerList,
				submit_preprocessing: {
					array_to_string: ['department', 'customer_type']
				},
				config: this.$config,
				form: this.$form.createForm(this, {
					name: "advanced_search"
				}),
				columns,
				status_list,
				locus_list: [],
				details: {},
				visible: false,
				add_visible: false,
				visible_drawer: false,
				add_time: '',
				form_data_seo: {
					list: [{
							type: "text",
							name: "keyword",
							title: "套餐名称",
							placeholder: '套餐名称',
							options: {}
						},

						{
							type: "select",
							name: "goods_id",
							title: "品项",
							options: {},
							list: []
						},
						{
							type: 'select',
							title: '状态',
							name: 'status',
							options: {},
							list: status_list
						},
						{
							type: "range_date",
							name: "range_date",
							title: "起止时间",
							options: {},
							start: {
								name: 'start_time'
							},
							end: {
								name: 'end_time'
							},
						},

					],
					...this.$config.form_data_seo
				},

				form_data: {
					title: '添加套餐',
					list: [{
							type: 'text',
							name: 'id',
							hidden: true,
							options: {
								initialValue: ''
							},
						},
						{
							type: 'text',
							name: 'name',
							title: '套餐名称',
							options: {
								initialValue: ''
							},
						},

						{
							type: 'select',
							name: 'level',
							title: '套餐等级',
							options: {
								initialValue: ''
							},
							list: []
						},
						{
							type: "text",
							name: "keyword",
							title: "套餐数量",
							placeholder: '套餐数量',
							options: {}
						},
						{
							type: "text",
							name: "keyword",
							title: "套餐价格",
							placeholder: '套餐价格',
							options: {}
						},
						{
							type: 'radio',
							name: 'status',
							title: '套餐状态',
							list: [{
									key: 1,
									value: "启用"
								},
								{
									key: 2,
									value: "禁用"
								}
							],
							options: {},
						},
						{
							type: "range_date",
							name: "range_date",
							title: "起止时间",
							options: {},
							start: {
								name: 'start_time'
							},
							end: {
								name: 'end_time'
							},
						},
						{
							type: 'slot',
							name: 'goods',
							title: '套餐品项',
							options: {},
						},
					]
				},
				bindingTransfer,
				scrapBinding,
				bindingLost,
				goods_model: '',
				send_img: false,
				edit_type: false,
				goods_code_list: []
			};
		},
		async created() {
			let query = this.$route.query;
			this.$method.get_department().then(res => {
				this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'department',
					'treeData', res)
			});
			this.$method.get_group().then(res => {
				this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'customer_type',
					'treeData', res)
			});
			this.$nextTick(() => {
				// 启用状态默认只筛选启用的
				this.$method.setSeoFormValue(this, {
					...query,
					use_status: 1,
				})
				this.$refs.list.get_list();
			})

		},
		methods: {
			select_material_submit({
			    data
			}) {
			    let obj = {
			        goods_id: data.id,
			        goods_title: data.goods_title,
			        goods_num: 0,
					  goods_price: 0,
					
			    }
			    this.material_list.push(obj)
			},
			add_material() {
			    this.material_visible = true
			},
			async handlePreview(file) {
				this.previewImage = file.url || file.response.url;
				this.previewVisible = true;
			},
			handleChangeImg(e) {
				this.nameplate_image = e ? e.fileList : [];
			},

			get_Binding_Info(data) {

			},
			add_submit(e) {


				saveBindingInfo({
					data: {

					},
					info: true
				}).then(res => {

					this.$refs.list.get_list();
					this.clear_info()
				})
			},
			success() {
				this.$refs.list.get_list();
			},



			handleCheckChange(e) {

			},
			select_customer_add({
				data
			}) {

			},
			add_customer() {
				this.add_pid_visible = true
			},
			add() {
				this.edit_type = false
				this.add_visible = true


			},
			clear_info() {



			},


			radioChange(e) {
				this.scrapReason = ''
				this.bindTransferReason = ''
			},

			edit(record) {
				this.scrapReason = '';
				this.select_customer = {};
				this.bindTransferReason = '';
				this.editing_freezer = record;
				this.visible = true;
			},
			delBing(record) {
				delGoodsBinding({
					data: {
						id: record.id
					},
					info: true
				}).then(res => {
					this.$refs.list.get_list();
				})
			},

			submit(e) {


			},
			report() {

			},

		}
	};
</script>

<style lang="less">
	@import url("../../assets/less/app.less");

	.drawer_title {
		padding: 15px 0;
	}

	.use_status {
		display: flex;
		align-items: center;

		.dot {
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background: @success-color;
			margin-right: 8px;
		}

		.red {
			background: @error-color;
		}
	}

	.input_item {
		display: flex;
		align-items: center;
		margin-top: 14px;

		.text {
			white-space: nowrap;
			font-size: 14px;
			margin-right: 4px;
		}
	}

	.tips {
		margin-top: 8px;
		color: @error-color;
		font-size: 14px;
	}

	.code_box {
		display: flex;
		align-items: center;

		div {
			flex: 1;
		}
	}
</style>